<template>
    <div class="box">

        <div v-if="!policy">
            <p>Policy not found</p>
        </div>

        <customer-upgrade-success :id="id" v-if="id.includes('success')"/>
        <customer-upgrade-failed v-else-if="id.includes('failed')"/>
        <customer-upgrade-form :policy="policy" :upgrade="upgrade" v-else-if="upgrade.step === 1"/>
        <customer-upgrade-comparison :policy="policy" :upgrade="upgrade" v-else-if="upgrade.step === 2"/>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import CustomerUpgradeForm from "./upgrade/CustomerUpgradeForm";
    import CustomerUpgradeComparison from "./upgrade/CustomerUpgradeComparison";
    import CustomerUpgradeSuccess from "./upgrade/CustomerUpgradeSuccess";
    import CustomerUpgradeFailed from "./upgrade/CustomerUpgradeFailed";

    export default {
        name: "CustomerPolicyUpgrade",
        data() {
            return {
                policy: null,
                upgrade: {
                    step: 1,
                    productChoices: null,
                    endorsementChoices: null,
                    endorsements: {},
                    product: null,
                    area: null,
                }
            }
        },
        props: ['id'],
        computed: {
            ...mapGetters({
                customer: 'customer',
            })
        },
        components: {
            CustomerUpgradeForm,
            CustomerUpgradeComparison,
            CustomerUpgradeSuccess,
            CustomerUpgradeFailed
        },
        mounted() {
            //
            this.$http.post('customer/policies', {
                'username': this.customer.email,
                'type': 'policy'
            })
            .then(res => {

                // Get the policy object
                this.policy = res.data.find(p => p.id === this.id);

                if(this.policy) {
                    return this.$http.get('policy/get/' + this.id);
                } else {
                    throw new Error('Policy not found');
                }
            })
            .then(res => {

                this.policy = res.data;

                this.upgrade.product = this.policy.quote.product;
                this.upgrade.area = this.policy.quote.travel_zone;

                // Check whether is a customer policy
                if(this.policy.details.email !== this.customer.email) {
                    this.$router.push({ name: 'customerPolicies' });
                } else {
                    return this.$c2g.getProductsOffered(this.policy.quote.id);
                }
            })
            .then(res => {
                this.upgrade.productChoices = JSON.parse(res.data);
                return this.$c2g.getEndorsements(this.policy.quote.type);
            })
            .then(res => {
                this.upgrade.endorsementChoices = res.data;
                for (const [key, value] of Object.entries(this.upgrade.endorsementChoices)) {
                    this.$set(this.upgrade.endorsements, key, this.policy.endorsements[key]);
                    console.log(value);
                }
            })
            .catch(err => {
                //this.$router.push({name: 'customerPolicies'});
                console.log(err);
            });
        }
    }
</script>

<style scoped>

</style>