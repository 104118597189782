<template>
    <div>
        <button class="button is-primary is-small" @click="prevStage">&lt;&nbsp;Go Back</button>

        <div v-if="upPolicy.policy.sell_price - policy.policy.sell_price > 0">
            <p class="title is-5 has-text-centered">Step 2 - Confirm your upgrade</p>

            <div class="columns" v-if="policy.policy.policy_product !== upgrade.product">
                <div class="column is-3 name-item title">Product:</div>
                <div class="column is-2 name-item">{{ $helper.stringCapFirst(policy.policy.policy_product) }}</div>
                <div class="column is-2 name-item">To</div>
                <div class="column is-2 name-item">{{ $helper.stringCapFirst(upgrade.product) }}</div>
                <div class="column is-3 name-item" style="color: green;" v-if="upPolicy.policy.sell_price !== 'silver'">Including Enhanced Covid-19 Cover</div>
            </div>

            <div class="columns" v-if="policy.quote.travel_zone !== upgrade.area">
                <div class="column is-3 name-item">Area:</div>
                <div class="column is-2 name-item">{{ $c2g.area[policy.quote.travel_zone] }}</div>
                <div class="column is-2 name-item">To</div>
                <div class="column is-2 name-item">{{ $c2g.area[upgrade.area] }}</div>
            </div>

            <!-- <div class="columns" v-if="policy.policy.covid19 === 'N' && (upgrade.product === 'gold' || upgrade.product === 'platinum')">
                <div class="column is-3 name-item">COVID-19 cover:</div>
                <div class="column is-3 name-item">No</div>
                <div class="column is-2"><i class="fas fa-long-arrow-alt-right"/></div>
                <div class="column is-4 name-item">Yes</div>
            </div> -->

            <!-- Fidelipay -->
            <form action='https://gateway.fidelipay.co.uk/paymentform/' method="post">
                <input v-for="(v, k) in payData" :key="v.id" type="hidden" :name="k" :value="v"/>
                <!-- Pay button -->
                <button type="submit" class="button is-primary is-medium is-fullwidth hvr-icon-forward b-shdw-1">
                    Pay &pound;{{ Number(upPolicy.policy.sell_price - policy.policy.sell_price).toFixed(2) }} 
                    (including IPT)
                </button>
            </form>
        </div>

        <div v-else>
            <p class="title is-5 has-text-centered">Step 2</p>
            <p class="has-text-centered">It isn't possible to downgrade your Policy, please <router-link :to="{name:'contactUs'}">Contact Us</router-link> if you need assistance.</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CustomerUpgradeComparison",
        data() {
            return {
                upPolicy: null,
                payData: null
            }
        },
        props: ['policy', 'upgrade'],
        methods: {
            prevStage() {
                this.upgrade.step = 1;
            },
        },
        computed: {
            ages() {
                let ages = [
                    this.policy.quote.age1, this.policy.quote.age2, this.policy.quote.age3, this.policy.quote.age4,
                    this.policy.quote.age5, this.policy.quote.age6, this.policy.quote.age7, this.policy.quote.age8
                ];
                return ages.filter(age => age !== "0");
            }
        },
        created() {
            // Create upgrade
            this.$http.post('policy/create', {
                'start_date': this.policy.quote.start_date,
                'end_date': this.policy.quote.end_date,
                'policy_type': this.policy.quote.type,
                'travel_zone': this.upgrade.area,
                'relationship': this.policy.quote.relationship,
                'ages': this.ages,
                'save': true
            })
            .then(res => {
                return this.$http.post('policy/select_product', {
                    'id': res.data.quote.id,
                    'product': this.upgrade.product
                });
            })
            .then(res => {
                return this.$http.post('policy/set_endorsements', {
                    'id': res.data.quote.id,
                    'endorsements': this.upgrade.endorsements
                })
            })
            .then(res => {
                this.upPolicy = res.data;
                return this.$http.post('pay/request/upgrade', {
                    'policynum': this.policy.quote.id,
                    'up_policynum': this.upPolicy.quote.id
                });
            })
            .then(res => {
                this.payData = res.data;
            })
            .catch(err => console.log(err));
        }
    }
</script>

<style lang="scss" scoped>
    .fa-long-arrow-alt-right {
        font-size: 50px;
        color: $c2g_blue;
    }
    .name-item {
        font-size: 20px;
    }
</style>