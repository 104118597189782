<template>
  <div class="box">
    <p class="title is-5 has-text-centered">My Policies</p>

    <b-loading :is-full-page="false" :active="loading" />

    <div v-if="policies !== null">
      <div v-for="ty in types" :key="ty">
        <p v-if="ty === 'live'" class="text-info">
          To view the details for your
          <strong>current and active</strong> Policies, please click on the
          Policy number below.
        </p>
        <p v-if="ty === 'expired'" class="text-info">
          To view the details for your <strong>expired</strong> Policies, please
          click on the Policy number below.
        </p>

        <b-loading
          :is-full-page="false"
          :active="!policies && ty === 'live'"
          :can-cancel="true"
        ></b-loading>

        <div class="policies-container">
          <table class="table is-fullwidth is-hoverable">
            <thead>
              <tr>
                <th></th>
                <th>Policy Number</th>
                <th>Policy Type</th>
                <th>Issue Date</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="p in ty === 'live' ? livePolicies : expiredPolicies"
                :key="p.id"
              >
                <td><i class="far status-icon" :class="tickCss(ty)" /></td>
                <td>C2G6283{{ p.id }}</td>
                <td>{{ $c2g.policyType[p.type] }}</td>
                <td>{{ $moment(p.issue_date).format('DD/MM/YYYY') }}</td>
                <td>{{ $moment(p.start_date).format('DD/MM/YYYY') }}</td>
                <td>{{ $moment(p.end_date).format('DD/MM/YYYY') }}</td>
                <td>
                  <router-link
                    tag="button"
                    class="button is-small is-primary"
                    :to="{ name: 'customerPolicy', params: { id: p.id } }"
                    >VIEW</router-link
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
      </div>
    </div>
    <div v-else-if="!loading && !policies" class="text-center">
      No policies found.
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CustomerPolicies',
  data() {
    return {
      policies: null,
      types: ['live', 'expired'],
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      customer: 'customer',
    }),
    livePolicies() {
      return this.policies.filter((p) => {
        return !this.$moment(p.end_date).isSameOrBefore(this.$moment(new Date().toISOString().split('T')[0]));
      });
    },
    expiredPolicies() {
      return this.policies.filter((p) => {
        return !this.$moment(p.end_date).isAfter(this.$moment(new Date().toISOString().split('T')[0]));
      });
    },
  },
  methods: {
    tickCss(type) {
      if (type === 'live') {
        return { 'fa-check-circle': true };
      } else {
        return { 'fa-times-circle': true };
      }
    },
  },
  created() {
    this.$http
      .post('customer/policies', {
        username: this.customer.email,
        limit: 20,
      })
      .then((res) => {
        this.policies = res.data;
        this.loading = false;
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style lang="scss" scoped>
.policies-container {
  max-height: 500px;
  overflow-y: scroll;
}
.text-info {
  margin-bottom: 10px;
  text-align: center;
}
.status-icon {
  font-size: 20px;
}
.fa-check-circle {
  color: green;
}
.fa-times-circle {
  color: red;
}
</style>
