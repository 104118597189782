<template>
    <div class="box">
        <p class="title is-5 has-text-centered">C2G6283{{ policy.quote.id }}</p>

        <table class="table is-fullwidth is-striped">
            <tbody>
            <tr>
                <td>Policy Type:</td>
                <td>{{ $c2g.policyType[policy.policy.policy_type] }}</td>
            </tr>
            <tr>
                <td>Product:</td>
                <td>{{ $helper.stringCapFirst(policy.policy.policy_product) }}</td>
            </tr>
            <tr>
                <td>Relationship:</td>
                <td>{{ $helper.stringCapFirst(policy.policy.relationship) }}</td>
            </tr>
            <tr>
                <td>Lead Passenger:</td>
                <td>{{`${policy.passengers[0].title} ${policy.passengers[0].forename} ${policy.passengers[0].surname}`}}</td>
            </tr>
            <tr>
                <td>Issue Date:</td>
                <td>{{ $moment(policy.policy.issue_date).format('DD/MM/YYYY') }}</td>
            </tr>
            <tr>
                <td>Start Date:</td>
                <td>{{ $moment(policy.policy.start_date).format('DD/MM/YYYY') }}</td>
            </tr>
            <tr>
                <td>End Date:</td>
                <td>{{ $moment(policy.policy.end_date).format('DD/MM/YYYY') }}</td>
            </tr>
            <tr>
                <td>Area:</td>
                <td>{{ $c2g.area[policy.policy.policy_area] }}</td>
            </tr>
            <tr>
                <td>Address:</td>
                <td>{{ policy.details.address1 }}</td>
            </tr>
            <tr>
                <td>Town:</td>
                <td>{{ policy.details.town }}</td>
            </tr>
            <tr>
                <td>County:</td>
                <td>{{ policy.details.county }}</td>
            </tr>
            <tr>
                <td>Postcode:</td>
                <td>{{ policy.details.postcode }}</td>
            </tr>
            <tr>
                <td>Telephone:</td>
                <td>{{ policy.details.telephone }}</td>
            </tr>
            <tr v-for="(k, v) in endorsements" :key="k.id" v-show="policy.endorsements[v] === 'Yes'">
                <td>{{ k[0] }}:</td>
                <td><i class="fas fa-check"/></td>
            </tr>
            <tr>
                <td>Premium:</td>
                <td>&pound;{{ policy.policy.sell_price }}</td>
            </tr>
            <tr>
                <td>IPT:</td>
                <td>&pound;{{ policy.policy.ipt }}</td>
            </tr>
            </tbody>
        </table>

        <div class="level">
            <div class="level-item">
                <button class="button is-info is-fullwidth">
                    <a :href="certLink" target="_blank">
                        <i class="fas fa-file-alt"/> View Certificate
                    </a>
                </button>
            </div>
            <div class="level-item">
                <button class="button is-info is-fullwidth">
                    <a :href="pwLink" target="_blank">
                        <i class="fas fa-book"/> Policy Wording
                    </a>
                </button>
            </div>
            <div class="level-item">
                <button class="button is-info is-fullwidth" @click="resendConfirmationEmail">
                    <i class="fas fa-paper-plane"/> Resend Confirmation Email
                </button>
            </div>
            <div class="level-item" v-if="this.$moment(policy.quote.end_date).isAfter(this.$moment())">
                <router-link tag="button" :to="{name: 'customerUpgradePolicy', params: { id: policy.quote.id }}" class="button is-info is-fullwidth">
                    <i class="fas fa-file-upload"/> Upgrade Policy
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import { policyAPI } from "../../../mixins/policy-api";
    import { mapGetters } from "vuex";

    export default {
        name: "CustomerPolicy",
        data() {
            return {
                policy: null,
                endorsements: [],
                certLink: null,
                pwLink: null,
                emailSent: false
            }
        },
        props: ['id'],
        mixins: [ policyAPI ],
        methods: {
            resendConfirmationEmail() {
                if(!this.emailSent) {
                    this.$http.post('confirmation_email', {
                        'id': this.policy.quote.id,
                        'to_email': this.customer.email
                    }).then(() => {
                        this.emailSent = true;
                        this.$buefy.toast.open({
                            duration: 4000,
                            message: `Your policy confirmation email has been sent!`,
                        });
                    }).catch(err => console.log(err));
                }
            }
        },
        computed: {
            ...mapGetters({
                customer: 'customer',
            })
        },
        created() {
            this.$http.get('policy/get/' + this.id)
            .then(({data}) => {
                // Get the policy object
                this.policy = data;

                // Check whether is a customer policy
                if(this.policy.details.email === this.customer.email) {
                    return this.$c2g.getEndorsements(this.policy.quote.type);
                } else {
                    this.$router.push({name: 'customerPolicies'});
                }
            })
            .then(res => {
                // Load endorsements
                this.endorsements = res.data;

                // Get the certificate link from the API
                this.certificateLink(this.policy.quote.id).then((res) => {
                    this.certLink = res.data;
                });
                // Get the policy wording link
                this.policyWordingLink(this.policy.quote.id).then((res) => {
                    this.pwLink = res.data;
                });
            })
            .catch(err => {
                this.$router.push({name: 'customerPolicies'});
                console.log(err)
            });
        }
    }
</script>

<style lang="scss" scoped>
    .level-item {
        margin: 5px 5px;
        padding-top: 7px !important;
        a {
            color: white !important;
        }
        .fas {
            margin-right: 5px;
        }
    }
</style>