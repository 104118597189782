<template>
  <div class="box">
    <p class="title is-5 has-text-centered">My Quotes</p>
    <p class="text-info">
      If your travel insurance quote was issued over 14 days ago, it will have
      expired.
    </p>
    <p class="text-info">
      <router-link :to="{ name: 'getQuote' }"
        >Click here to get a new quote.</router-link
      >
    </p>

    <div class="policies-container">
      <b-loading
        :is-full-page="false"
        :active="loading"
        :can-cancel="true"
      ></b-loading>

      <table class="table is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>Policy Number</th>
            <th>Policy Type</th>
            <th>Issue Date</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Status</th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="p in policies" :key="p.id">
            <td>C2G6283{{ p.id }}</td>
            <td>{{ $c2g.policyType[p.type] }}</td>
            <td>{{ $moment(p.issue_date).format('DD/MM/YYYY') }}</td>
            <td>{{ $moment(p.start_date).format('DD/MM/YYYY') }}</td>
            <td>{{ $moment(p.end_date).format('DD/MM/YYYY') }}</td>

            <td v-if="p.status === 'policy'">
              Purchased
            </td>
            <td
              v-else-if="
                $moment(p.start_date).isSameOrAfter(
                  $moment(new Date().toISOString().split('T')[0])
                )
              "
            >
              Live
            </td>
            <td v-else>
              Expired
            </td>

            <td v-if="p.status === 'policy'">
              <button
                class="button is-small is-primary"
                style="background-color: blue;"
              >
                PURCHASED
              </button>
            </td>
            <td
              v-if="
                $moment(p.start_date).isSameOrAfter(
                  $moment(new Date().toISOString().split('T')[0])
                )
              "
            >
              <router-link
                tag="button"
                class="button is-small is-primary"
                :to="{ name: 'getQuote' }"
                >PURCHASE</router-link
              >
            </td>
            <td v-else>
              <!-- <router-link
                tag="button"
                class="button is-small" style="background-color: green; color: white;"
                :to="{ name: 'getQuote' }"
                >NEW QUOTE</router-link
              > -->
              <button
                class="button is-small is-primary"
                @click="createNewQuoteAndNavigate(p)"
              >
                NEW QUOTE
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CustomerQuotes',
  data() {
    return {
      policies: null,
      loading: true,
    };
  },
  methods: {
    ...mapActions({
      setPolicy: 'setPolicy',
      setReqPolicy: 'setReqPolicy',
    }),
    async createNewQuoteAndNavigate(policy) {
      const { id } = policy;

      // Fetch original quote
      const originalQuote = await this.$http.get('policy/get/' + id);

      const {
        start_date,
        end_date,
        type,
        travel_zone,
        relationship,
        cruise,
        trip_cost,
      } = originalQuote.data.quote;

      const {
        email,
        address1,
        address2,
        address3,
        town,
        county,
        postcode,
        telephone,
        mobile,
      } = this.customer;

      const ages = [];
      for (let i = 1; i < 8; i++) {
        let age = originalQuote.data.quote['age' + i];
        if (age > 0) {
          ages.push(age);
        }
      }

      const duration =
        this.$moment(end_date).diff(this.$moment(start_date), 'days') + 1;
      const startDate = this.$moment(new Date()).format('YYYY-MM-DD');
      const endDate = this.$moment(startDate, 'YYYY-MM-DD')
        .add(duration, 'days')
        .format('YYYY-MM-DD');

      // Create new quote
      const res = await this.$http.post('policy/create', {
        start_date: startDate,
        end_date: endDate,
        policy_type: type,
        travel_zone,
        relationship,
        ages,
        cruise,
        trip_cost,
        save: true,
      });

      res.data.quote.email_address = email;
      res.data.details.email = email;
      res.data.details.address1 = address1;
      res.data.details.address2 = address2;
      res.data.details.address3 = address3;
      res.data.details.county = county;
      res.data.details.mobile = mobile;
      res.data.details.postcode = postcode;
      res.data.details.town = town;
      res.data.details.telephone = telephone;

      // passengers.push({ paxid: '', title: '', forename: '', surname: '', age: ageWorkout, dob: dobString, status: status });

      // this.$http.post('policy/set_passengers', {
      //     'id': id,
      //     'passengers': passengers
      // });

      const pax = originalQuote.data.passengers.map(
        ({ forename, surname, title, dob, status, age }) => ({
          title,
          status,
          forename,
          surname,
          dob,
          paxid: '',
          age
        })
      );

      console.log(pax);

      let res1 = await this.$http.post('policy/save', {
        id: res.data.quote.id,
        policy: JSON.stringify(res.data.policy),
        details: JSON.stringify(res.data.details),
        endorsements: JSON.stringify(res.data.endorsements),
        quote: JSON.stringify(res.data.quote),
        passengers: null,
      });

      res1 = await this.$http.post('policy/set_passengers', {
        id: res.data.quote.id,
        passengers: pax,
      });

      this.setPolicy(res1.data);
      this.setReqPolicy(res1.data);
      this.$router.push({ name: 'policyDetails' });
    },
  },
  computed: {
    ...mapGetters({
      customer: 'customer',
      policy: 'policy',
    }),
  },
  created() {
    this.$http
      .post('customer/policies', {
        username: this.customer.email,
        limit: 40,
        type: 'quote',
      })
      .then((res) => {
        this.policies = res.data;
        this.loading = false;
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style lang="scss" scoped>
.policies-container {
  max-height: 500px;
  overflow-y: scroll;
}
.text-info {
  margin-bottom: 10px;
  text-align: center;
}
</style>
