<template>
  <div class="box">
    <p class="title is-5 has-text-centered">Feedback</p>

    <div v-if="!sent">
      <b-field
        label="In order for us to improve our service, if you have any feedback on the services we provide, please leave a brief message below."
      >
        <b-input maxlength="200" type="textarea" v-model="message"></b-input>
      </b-field>

      <button class="button is-info is-fullwidth" @click="send">SEND</button>
    </div>
    <div v-if="sent">
      <div class="text-center">
        <p>Thanks for sending us your thoughts {{this.customer.title}} {{this.customer.surname}}.</p>
        <p>
          We really appreciate it since we are always looking for ways to make
          Covered2go even better.
        </p>
        <p>Thanks,</p>
        <p>Your Covered2go Team</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      message: '',
      sent: false,
    };
  },
  computed: {
    ...mapGetters({
      customer: 'customer',
    }),
  },
  methods: {
    send() {
      this.$http
        .post('customer/email', {
          type: 'feedback',
          message: this.message,
          customer_name:
            this.customer.title +
            ' ' +
            this.customer.forename +
            ' ' +
            this.customer.surname,
          customer_email: this.customer.email,
        })
        .then(() => {
          //   console.log(res);
          this.sent = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped></style>
