<template>
    <div class="box">
        <p class="title is-5 has-text-centered">Profile</p>
        <p class="text-info">Below are the details we have stored on file for you.</p>

        <div class="box">
            <div class="columns is-multiline">
                <div class="column title-col is-half">Full Name:</div>
                <div class="column is-half">{{customer.title}} {{customer.forename}} {{customer.surname}}</div>

                <div class="column title-col is-half">Date of Birth:</div>
                <div class="column is-half">{{$moment(customer.dob).format('DD/MM/YYYY')}}</div>

                <div class="column title-col is-half">Email Address:</div>
                <div class="column is-half">{{customer.email}}</div>

                <div class="column title-col is-half">Address:</div>
                <div class="column is-half">{{customer.address1}}</div>

                <div class="column title-col is-half">Town:</div>
                <div class="column is-half">{{customer.town}}</div>

                <div class="column title-col is-half">County:</div>
                <div class="column is-half">{{customer.county}}</div>

                <div class="column title-col is-half">Postcode:</div>
                <div class="column is-half">{{customer.postcode}}</div>

                <div class="column title-col is-half">Telephone:</div>
                <div class="column is-half">{{customer.telephone}}</div>

                <div class="column title-col is-half">Mobile:</div>
                <div class="column is-half">{{customer.mobile}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";

    export default {
        name: "CustomerProfile",
        computed: {
            ...mapGetters({
                customer: 'customer',
            })
        },
    }
</script>

<style scoped>
    .text-info {
        margin-bottom: 10px;
        text-align: center;
    }
    .title-col {
        font-weight: bold;
    }
</style>