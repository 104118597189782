<template>
  <div class="box">
    <p class="title is-5 has-text-centered">Contact Us</p>

    <div v-if="!sent">
      <b-field
        label="If you have a query regarding your policy then please contact us with a message below."
      >
        <b-input maxlength="200" type="textarea" v-model="message"></b-input>
      </b-field>

      <button class="button is-info is-fullwidth" @click="send">SEND</button>
    </div>

    <div v-if="sent">
      <p class="text-center">
        Thank you for sending us a message, a member of our team will be in
        touch as soon as possible.
      </p>
    </div>

    <br><br>

    <contact-us-box></contact-us-box>

  </div>
</template>

<script>
import ContactUsBox from '@/components/content/ContactUsBox.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    ContactUsBox
  },
  name: 'CustomerContactUs',
  data() {
    return {
      message: '',
      sent: false,
      content: ''
    };
  },
  computed: {
    ...mapGetters({
      customer: 'customer',
    }),
  },
  methods: {
    send() {
      this.$http
        .post('customer/email', {
          type: 'contact',
          message: this.message,
          customer_name:
            this.customer.title +
            ' ' +
            this.customer.forename +
            ' ' +
            this.customer.surname,
          customer_email: this.customer.email,
        })
        .then(() => {
          //   console.log(res);
          this.sent = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.$httpContent
      .get(`page/covered2go/contactus`)
      .then((res) => {
        this.content = JSON.parse(JSON.parse(res.data)[0].content);
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style scoped></style>
